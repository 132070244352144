<template>
  <div class="home">
    <img src="/img/logo.svg" alt="Logo Instituto Burmann & Bastos" class="logo">
    <transition name="page" mode="out-in">
      <div class="d-p" style="height: calc(100vh - 100px)">
        <p class="loading-text">Carregando...</p>
        <div id="cardapio">
          <pdf :src="menu"></pdf>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import {HTTP} from "@/helpers/http";
import pdf from 'vue-pdf';

export default {
  name: 'Menu',
  components: {
    pdf
  },
  data: () => {
    return {
      loading: true,
      menu: ''
    }
  },
  async created() {
    HTTP.get('cardapio')
        .then(response => {
          this.loading = false;
          this.menu = response.data.menu;
        })
        .catch(err => {
          this.$snotify.error(err.response.data.message, 'Ooops');
        });
  }
}
</script>

<style scoped lang="scss">
  .home {
    padding-top: 25px;
    text-align: center;
    position: relative;

    .logo {
      max-width: 90%;
      width: 150px;
      height: auto;
      position: relative;
    }

    #cardapio {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
</style>
